import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 三星临增益 from './技能增益/三星临'
import 兵主逆增益 from './技能增益/兵主逆'
import 天斗旋增益 from './技能增益/天斗旋'
import 鬼星开穴增益 from './技能增益/鬼星开穴'
import 亘天增益 from './技能增益/亘天'
import 九字诀山卦通用增益 from './技能增益/通用增益/九字诀山卦通用增益'
import { 属性类型 } from '@/@types/属性'
import 通用外功增益 from './技能增益/通用增益/通用外功'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 非山卦三星临: Partial<技能基础数据模型> = {
  统计名称: '三星临',
  秘籍依赖技能: '三星临',
  技能伤害系数: 275.51700000000005,
  基础伤害_基础值: 88,
  基础伤害_浮动值: 56,
  技能增益列表: 三星临增益,
}

const 非山卦兵主逆: Partial<技能基础数据模型> = {
  统计名称: '兵主逆',
  秘籍依赖技能: '兵主逆',
  技能伤害系数: 294.0,
  基础伤害_基础值: 188, // 有bug rand写到damage上了
  技能增益列表: 兵主逆增益,
}

const 非山卦天斗旋: Partial<技能基础数据模型> = {
  统计名称: '天斗旋',
  秘籍依赖技能: '天斗旋',
  技能伤害系数: 942.3480000000003,
  基础伤害_基础值: 620,
  基础伤害_浮动值: 425,
  技能增益列表: 天斗旋增益,
}

const 鬼星开穴: Partial<技能基础数据模型> = {
  统计名称: '鬼星开穴',
  秘籍依赖技能: '鬼星开穴',
  技能伤害系数: 675.0, // 18级数据，19 20 21为点了奇穴 擎羊 的情况，一般点不出来
  基础伤害_基础值: 766,
  基础伤害_浮动值: 525,
  技能增益列表: 鬼星开穴增益,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32886',
    技能名称: '破', // 32886
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (1.4168 * 1.4 * 0.88 - 1)),
    技能增益列表: 通用增益,
    技能等级数据: {
      2: {
        技能伤害系数: 获取破招实际系数(1048576 * (1.4168 * 0.3 * 2.3 * 0.4 - 1)),
      },
    },
  },
  {
    技能ID: '25512',
    技能名称: '魂击', // 25512
    伤害类型: '外功',
    技能伤害系数: 21,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用外功增益,
      {
        增益名称: '普通攻击增伤',
        增益所在位置: '职业',
        增益类型: '全局启用',
        增益启用: true,
        增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
      },
    ],
  },
  {
    技能ID: '24558',
    技能名称: '三星临',
    ...非山卦三星临,
  },
  {
    技能ID: '24675',
    技能名称: '三星临·水',
    ...非山卦三星临,
  },
  {
    技能ID: '24677',
    技能名称: '三星临·火',
    ...非山卦三星临,
  },
  {
    技能ID: '24811',
    技能名称: '兵主逆',
    ...非山卦兵主逆,
  },
  {
    技能ID: '24812',
    技能名称: '兵主逆·水',
    ...非山卦兵主逆,
  },
  {
    技能ID: '24814',
    技能名称: '兵主逆·火',
    ...非山卦兵主逆,
  },
  {
    技能ID: '24821',
    技能名称: '天斗旋',
    ...非山卦天斗旋,
  },
  {
    技能ID: '24822',
    技能名称: '天斗旋·水',
    ...非山卦天斗旋,
  },
  {
    技能ID: '24824',
    技能名称: '天斗旋·火',
    ...非山卦天斗旋,
  },
  {
    技能ID: '24676',
    技能名称: '三星临·山',
    统计名称: '三星临',
    秘籍依赖技能: '三星临',
    技能伤害系数: 289.29285000000004,
    基础伤害_基础值: 92,
    基础伤害_浮动值: 58,
    技能增益列表: [...三星临增益, ...九字诀山卦通用增益],
  },
  {
    技能ID: '24813',
    技能名称: '兵主逆·山',
    统计名称: '兵主逆',
    秘籍依赖技能: '兵主逆',
    技能伤害系数: 308.7,
    基础伤害_基础值: 197,
    技能增益列表: [...兵主逆增益, ...九字诀山卦通用增益],
  },
  {
    技能ID: '24823',
    技能名称: '天斗旋·山',
    统计名称: '天斗旋',
    秘籍依赖技能: '天斗旋',
    技能伤害系数: 989.4654000000004,
    基础伤害_基础值: 651,
    基础伤害_浮动值: 446,
    技能增益列表: [...天斗旋增益, ...九字诀山卦通用增益],
  },
  {
    技能ID: '24454',
    技能名称: '卦象·火离',
    技能伤害系数: 200,
    基础伤害_基础值: 75,
    基础伤害_浮动值: 43,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '24870',
    技能名称: '鬼星开穴',
    ...鬼星开穴,
  },
  {
    技能ID: '37311',
    技能名称: '鬼星开穴·龙回首',
    ...鬼星开穴,
  },
  {
    技能ID: '33236',
    技能名称: '列宿游',
    统计名称: '列宿游',
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,17': {
        技能伤害系数: (i) => (800 + 800 * i * 0.03) * 1.15 * 0.283,
      },
    },
  },
  {
    技能ID: '28815',
    技能名称: '纵横三才',
    统计名称: '纵横三才',
    基础伤害_基础值: 30,
    基础伤害_浮动值: 10,
    技能伤害系数: 350.0,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,61': {
        技能伤害系数: (i) => {
          if (+i === 2) {
            return 648.0
          } else if (+i === 3) {
            return 1100.0
          } else {
            return 350.0
          }
        },
        基础伤害_基础值: [
          30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540,
          570, 600, 630, 660, 690, 720, 750, 780, 810, 840, 870, 900, 930, 960, 990, 1020, 1050,
          1080, 1110, 1140, 1170, 1200, 1230, 1260, 1290, 1320, 1350, 1380, 1410, 1440, 1470, 1500,
          1530, 1560, 1590, 1620, 1650, 1680, 1710, 1740, 1770, 1800,
        ],
        基础伤害_浮动值: [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
          210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380,
          390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560,
          570, 580, 590, 600,
        ],
      },
    },
  },
  {
    技能ID: '30847',
    技能名称: '天网',
    技能伤害系数: 100,
    基础伤害_基础值: 50,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34683',
    技能名称: '亘天',
    秘籍依赖技能: '天斗旋',
    技能伤害系数: 297.0,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 亘天增益,
  },
  {
    技能ID: '25174',
    技能名称: '灵器',
    技能伤害系数: 216.0,
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37599',
    技能名称: '阵星入舆',
    技能伤害系数: 297,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37599',
    技能名称: '三星临·神兵',
    技能伤害系数: 50,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '33588',
    技能名称: '神兵·合灯',
    技能伤害系数: 700.9200000000001,
    基础伤害_基础值: 120,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  三星临增益,
  兵主逆增益,
  天斗旋增益,
  鬼星开穴增益,
  亘天增益,
}

export { 技能增益 }
