// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zpArpVXglRCo0ErGhlVB {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
  justify-content: center;
}
.H2Gb1CgE6D2EV1UWarlM {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
}
.i9QG0r_QBYhTiJEsC9aL {
  display: flex;
  align-items: center;
}
.i9QG0r_QBYhTiJEsC9aL .h0noFnbVRNjYWETuBD9i {
  font-size: 12px;
  margin-left: 12px;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step0_入口页/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;AACF;AAEA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;AADF;AADA;EAKI,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":[".content {\n  display: flex;\n  height: 100%;\n  align-items: center;\n  font-size: 16px;;\n  justify-content: center;\n}\n\n.text {\n  font-size: 16px;\n  line-height: 22px;\n  margin-bottom: 12px;\n}\n\n.entry {\n  display: flex;\n  align-items: center;\n\n  .tip {\n    font-size: 12px;\n    margin-left: 12px;\n    margin-bottom: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `zpArpVXglRCo0ErGhlVB`,
	"text": `H2Gb1CgE6D2EV1UWarlM`,
	"entry": `i9QG0r_QBYhTiJEsC9aL`,
	"tip": `h0noFnbVRNjYWETuBD9i`
};
export default ___CSS_LOADER_EXPORT___;
