// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T3bHUlJkoytmNPbWWKzf {
  width: 100%;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: relative;
  padding: 48px 64px;
}
.Hl0J2qSQeb04lM7lgxgp {
  max-width: 1264px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.WOaDt7b8KegwhqCUJLef {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/index.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,oCAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAEA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AAAF;AAGA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;AADF","sourcesContent":[".content {\n  width: 100%;\n  border-radius: 12px;\n  background-color: rgba(0, 0, 0, 0.8);\n  color: #fff;\n  position: relative;\n  padding: 48px 64px;\n}\n\n.main {\n  max-width: 1264px;\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n}\n\n.title {\n  position: absolute;\n  left: 10px;\n  bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `T3bHUlJkoytmNPbWWKzf`,
	"main": `Hl0J2qSQeb04lM7lgxgp`,
	"title": `WOaDt7b8KegwhqCUJLef`
};
export default ___CSS_LOADER_EXPORT___;
