// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RWBF7bl03TVJ8Ap0wRpY {
  position: fixed;
  bottom: 36px;
  right: 0px;
  cursor: pointer;
}
.RWBF7bl03TVJ8Ap0wRpY:hover .d2BW2qXttBAbGn_Gr0to {
  background: #000000;
}
.ypmxJlpOE36cVrrG9bJ_ {
  width: 60px;
  position: absolute;
  left: -30px;
  bottom: 0;
}
.d2BW2qXttBAbGn_Gr0to {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px 12px 8px 30px;
  transition: 0.5s;
}
.yupBvm5sYq6_a89_9p8T {
  font-size: 14px;
  box-shadow: 1px 1px rgba(255, 255, 255, 0.3);
  background: linear-gradient(270deg, rgba(255, 0, 127, 0.8), rgba(255, 127, 0, 0.8), rgba(127, 255, 0, 0.8), rgba(0, 255, 127, 0.8), rgba(0, 255, 255, 0.8), rgba(0, 127, 255, 0.8), rgba(0, 0, 255, 0.8), rgba(127, 0, 255, 0.8), rgba(255, 0, 255, 0.8));
  background-size: 400%;
  /* 渐变背景大小 */
  -webkit-background-clip: text;
  /* 使背景仅应用于文本 */
  -webkit-text-fill-color: transparent;
  /* 使文本填充颜色透明 */
  animation: YRzNvB6WF7b1Q5sesX_M 5s linear infinite;
  /* 动画效果 */
}
@keyframes YRzNvB6WF7b1Q5sesX_M {
  0% {
    background-position: 0% 50%;
    /* 动画起始位置 */
  }
  100% {
    background-position: 100% 50%;
    /* 动画结束位置 */
  }
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/年度报告入口/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AACF;AAAE;EAEI,mBAAA;AACN;AAIA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AAFF;AAKA;EAEE,8BAAA;EACA,kBAAA;EACA,0BAAA;EACA,gBAAA;AAJF;AAOA;EACE,eAAA;EACA,4CAAA;EAGA,yPAAA;EACA,qBAAA;EAPA,WAAW;EAQX,6BAAA;EANA,cAAc;EAOd,oCAAA;EALA,cAAc;EAMd,kDAAA;EAJA,SAAS;AACX;AAMA;EACI;IACI,2BAAA;IAJJ,WAAW;EACb;EAKE;IACI,6BAAA;IAHJ,WAAW;EACb;AACF","sourcesContent":[".enter{\n  position: fixed;\n  bottom: 36px;\n  right: 0px;\n  cursor: pointer;\n  &:hover {\n    .content {\n      background: rgba(0, 0, 0, 1);\n    }\n  }\n}\n\n.img {\n  width: 60px;\n  position: absolute;\n  left: -30px;\n  bottom: 0;\n}\n\n.content {\n  // background-color: rgba(255, 255, 255, 0.5);\n  background: rgba(0, 0, 0, 0.3);\n  border-radius: 4px;\n  padding: 8px 12px 8px 30px;\n  transition: .5s;\n}\n\n.text {\n  font-size: 14px;\n  box-shadow: 1px 1px rgba(255, 255, 255, 0.3);\n  // opacity: 0.6;\n  // font-weight: bold; /* 字体加粗 */\n  background: linear-gradient(270deg, rgba(255, 0, 127, 0.8), rgba(255, 127, 0, 0.8), rgba(127, 255, 0, 0.8), rgba(0, 255, 127, 0.8), rgba(0, 255, 255, 0.8), rgba(0, 127, 255, 0.8), rgba(0, 0, 255, 0.8), rgba(127, 0, 255, 0.8), rgba(255, 0, 255, 0.8));\n  background-size: 400%; /* 渐变背景大小 */\n  -webkit-background-clip: text; /* 使背景仅应用于文本 */\n  -webkit-text-fill-color: transparent; /* 使文本填充颜色透明 */\n  animation: rainbow 5s linear infinite; /* 动画效果 */\n}\n\n@keyframes rainbow {\n    0% {\n        background-position: 0% 50%; /* 动画起始位置 */\n    }\n    100% {\n        background-position: 100% 50%; /* 动画结束位置 */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enter": `RWBF7bl03TVJ8Ap0wRpY`,
	"content": `d2BW2qXttBAbGn_Gr0to`,
	"img": `ypmxJlpOE36cVrrG9bJ_`,
	"text": `yupBvm5sYq6_a89_9p8T`,
	"rainbow": `YRzNvB6WF7b1Q5sesX_M`
};
export default ___CSS_LOADER_EXPORT___;
