import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// import 测试 from './测试.json'
import 威声165 from './威声165.json'
import 威声 from './威声.json'
import 无影威声 from './无影威声.json'
import 威声一键宏 from './威声一键宏.json'
import 电逝 from './电逝.json'
import 周流 from './周流.json'
import 橙武 from './橙武.json'
import 橙武威声 from './橙武威声.json'
import 橙武周流 from './橙武周流.json'

const 计算循环: 循环数据[] = [
  威声165,
  威声,
  无影威声,
  威声一键宏,
  电逝,
  周流,
  橙武,
  橙武威声,
  橙武周流,
] as 循环数据[]

export default 计算循环
