// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T0dGdzOqzjflR9ci294d {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.T0dGdzOqzjflR9ci294d.N607vpeOpdtdO_uWbVoy {
  cursor: pointer;
}
.FQvu8LVxOXQtMw0OCfsD {
  transition: 0.2s;
}
.GHqqjHGy8t_GVgJvNVzg {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 12px;
}
.elVX85Zi6SmRsCt3oaD2 {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 400;
}
.cKGTzQOItNaM7rDfKqiy {
  font-size: 48px;
  font-weight: 500;
  margin: 0 12px;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step3_流量统计/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,gBAAA;AADF;AAIA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAFF;AAKA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJF","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  transition: .2s;\n}\n\n.title {\n  font-size: 34px;\n  font-weight: bold;\n  margin-bottom: 12px;\n}\n\n.subTitle {\n  font-size: 22px;\n  margin-bottom: 24px;\n  font-weight: 400;\n}\n\n.import {\n  font-size: 48px;\n  font-weight: 500;\n  margin: 0 12px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `T0dGdzOqzjflR9ci294d`,
	"next": `N607vpeOpdtdO_uWbVoy`,
	"content": `FQvu8LVxOXQtMw0OCfsD`,
	"title": `GHqqjHGy8t_GVgJvNVzg`,
	"subTitle": `elVX85Zi6SmRsCt3oaD2`,
	"import": `cKGTzQOItNaM7rDfKqiy`
};
export default ___CSS_LOADER_EXPORT___;
