// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chUrvd8t99ybkfX7_4DZ {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chUrvd8t99ybkfX7_4DZ.EmwC62hRoJNq4a9ZsIgm {
  cursor: pointer;
}
.JdFBuXVc5mk6_C1nW9K9 {
  transition: 0.2s;
}
.tQaUk8DxuP32GhtNjEZd {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 12px;
}
.mfY_o0IRPUepnrfdrALm {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 400;
}
.Nx33Ln9DgWj59LdWMJaI {
  font-size: 48px;
  font-weight: 500;
  margin: 0 12px;
}
.WON6hQtNhknA3UykcBx0 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px -4px 24px;
}
.WON6hQtNhknA3UykcBx0 img {
  width: 32px;
  margin: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step7_使用情况统计/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,gBAAA;AADF;AAIA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAFF;AAKA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AALF;AACA;EAOI,WAAA;EACA,WAAA;AALJ","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  transition: .2s;\n}\n\n.title {\n  font-size: 34px;\n  font-weight: bold;\n  margin-bottom: 12px;\n}\n\n.subTitle {\n  font-size: 22px;\n  margin-bottom: 24px;\n  font-weight: 400;\n}\n\n.import {\n  font-size: 48px;\n  font-weight: 500;\n  margin: 0 12px\n}\n\n.iconList {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin: -4px -4px 24px;\n\n  img {\n    width: 32px;\n    margin: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `chUrvd8t99ybkfX7_4DZ`,
	"next": `EmwC62hRoJNq4a9ZsIgm`,
	"content": `JdFBuXVc5mk6_C1nW9K9`,
	"title": `tQaUk8DxuP32GhtNjEZd`,
	"subTitle": `mfY_o0IRPUepnrfdrALm`,
	"import": `Nx33Ln9DgWj59LdWMJaI`,
	"iconList": `WON6hQtNhknA3UykcBx0`
};
export default ___CSS_LOADER_EXPORT___;
