// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QaMNQnXohJ2DmiDRPbBw {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.QaMNQnXohJ2DmiDRPbBw.oxVB7LZAbglQ54yhhSod {
  cursor: pointer;
}
.RgZdZAE2pJlbhh66Virg {
  width: 100%;
}
.WpRrA7PEkmv5t6RZSE5a {
  width: 100%;
  display: flex;
  align-items: center;
}
.RgZdZAE2pJlbhh66Virg {
  transition: 0.2s;
}
.vM5ybDJs_1fHFsY4TKiF {
  width: 30%;
  height: 400px;
}
.WEDGHEzTaFm2R5gPGuJI {
  flex: 1;
  height: 400px;
}
.rNwmli3SnAQEHzdEWImQ {
  font-size: 24px;
  font-weight: bold;
  margin: 48px auto 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/页面/年度报告/报告组件/Step4_心法流量统计/index.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,WAAA;AADF;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAMA;EACE,UAAA;EACA,aAAA;AAJF;AAOA;EACE,OAAA;EACA,aAAA;AALF;AAQA;EAEE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AAPF","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.next {\n    cursor: pointer;\n  }\n}\n\n.content {\n  width: 100%;\n}\n\n.chartWrap {\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.content {\n  transition: .2s;\n}\n\n.viewChart {\n  width: 30%;\n  height: 400px;\n}\n\n.lineChart {\n  flex: 1;\n  height: 400px;\n}\n\n.title {\n  \n  font-size: 24px;\n  font-weight: bold;\n  margin: 48px auto 0;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `QaMNQnXohJ2DmiDRPbBw`,
	"next": `oxVB7LZAbglQ54yhhSod`,
	"content": `RgZdZAE2pJlbhh66Virg`,
	"chartWrap": `WpRrA7PEkmv5t6RZSE5a`,
	"viewChart": `vM5ybDJs_1fHFsY4TKiF`,
	"lineChart": `WEDGHEzTaFm2R5gPGuJI`,
	"title": `rNwmli3SnAQEHzdEWImQ`
};
export default ___CSS_LOADER_EXPORT___;
